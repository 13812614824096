(function () {
  'use strict';

  angular
  .module('neo.home.entitats.newEntitat')
  .config(config);

  function config($stateProvider) {
    $stateProvider
    .state('home.entitats.newEntitat', {
      url: '/new',
      templateUrl: 'home/entitats/new-entitat/new-entitat.tpl.html',
      controller: 'NewEntitatCtrl',
      controllerAs: 'vm',
      resolve: {
        // acl: function ($q, AclService) {
        //   if (AclService.can('ENTITY', 'C')) {
        //     // Has proper permissions
        //     return true;
        //   } else {
        //     // Does not have permission
        //     return $q.reject('Unauthorized');
        //   }
        // },
        towns: function (Poblacions) {
          return Poblacions.query().$promise;
        },
        townhalls : function(Ajuntaments){
          return Ajuntaments.query().$promise;
        }
      }
    });
  }
}());
